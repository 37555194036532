import { data_2024 } from "../../../data/cbse-enclosure-data";

const data = [
  {
    date: "03-10-2024",
    name: "Amrit Kaal Story-Writing Competition for Students (Grades 9 to 12)",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/cbse/Amrit-Kaal-Story-Writing-Competition%28Grades-9-to-12%29.pdf",
  },
  {
    date: "19-09-2024",
    name: "LOC Data Submission",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/cbse/LOC_Data_Submission_2024.pdf",
  },
  ...data_2024,
  {
    date: "14-02-2024",
    name: "Advisory Schools Parents Students",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/cbse/Advisory_Schools_Parents_Students_14022024.pdf",
  },
  {
    date: "08-02-2024",
    name: "DOE Examination Cell",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/cbse/DOE_Examination_Cell_2024.pdf",
  },
  {
    date: "24-01-2024",
    name: "Pariksha Pe Charcha (PPC) ",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/Pariksha-Pe-Charcha-2024.pdf",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2023-24/DATE_SHEET_CLASS_XII_Revised_03012024.pdf",
    date: "03-01-2024",
    name: "DATE SHEET CLASS XII",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/2023-24/DATE_SHEET_CLASS_X_Revised_03012024.pdf",
    date: "03-01-2024",
    name: "DATE SHEET CLASS X",
  },
  {
    date: "24-05-2023",
    name: "Mandatory Public Disclosure - Appendix - IX",
    pageLink: "/mandatory-public-disclosure-appendix-IX",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/APPENDIX-IX-MANDATORY-PUBLIC-DISCLOSURE.pdf",
  },
  {
    date: "24-05-2023",
    name: "Annexure-1(a) - Affiliation Upgradation Letter",
    pageLink: "/annexure/affiliation-upgradation-letter",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-1(a)-Affiliation-Upgradation-Letter.pdf",
  },
  {
    date: "24-05-2023",
    name: "Annexure-1(b) - Extension of Provisional Affiliation",
    pageLink: "/annexure/extension-of-provisional-affiliation",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-1-(b)-Extension-of-Provisional-Affiliation.pdf",
  },
  {
    date: "24-05-2023",
    name: "Annexure-2 - Copies of Societies Trust Company Registration Renewal Certificate",
    pageLink:
      "/annexure/copies-of-societies-trust-company-registration-renewal-certificate",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-2-Copies-of-Societies-Trust-Company-Registration-Renewal-Certificate.pdf",
  },
  {
    date: "24-05-2023",
    name: "Annexure-3 - No Objection Certificate",
    pageLink: "/annexure/no-objection-certificate",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/annexure-3-no-objection-certificate.pdf",
  },
  {
    date: "24-05-2023",
    name: "Annexure-4 - Recognition Certificate",
    pageLink: "/annexure/recognition-certificate",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-4-Recognition-Certificate.pdf",
  },
  {
    date: "24-05-2023",
    name: "Annexure-5 - Building Safety Certificate",
    pageLink: "/annexure/building-safety-certificate",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-5-Building-Safety-Certificate.pdf",
  },
  {
    date: "24-05-2023",
    name: "Annexure-6 - Fire Safety Certificate",
    pageLink: "/annexure/fire-safety-certificate",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-6-Fire-Safety-Certificate.pdf",
  },
  {
    date: "24-05-2023",
    name: "Annexure-7 - Affiliation Upgradation Extension of Affiliation Letter",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-7-Affiliation-Upgradation-Extension-of-Affiliation-Letter.pdf",
    pageLink:
      "/annexure/affiliation-upgradation-extension-of-affiliation-letter",
  },
  {
    date: "24-05-2023",
    name: "Annexure-8 - Water, Health and Sanitation Certificate",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-8-Water-Health-and-Sanitation-Certificates.pdf",
    pageLink: "/annexure/health-certificate",
  },
  {
    date: "24-05-2023",
    name: "Annexure-9 - Fee Structure Session - 2023-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-9-FEE-STRUCTURE-SESSION-2023-2024.pdf",
    pageLink: "/annexure/fee-structure-session-2023-2024",
  },
  {
    date: "24-05-2023",
    name: "Annexure-10 - Annual Academic Calendar - 2023-24",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-10-Annual-Academic-Calendar-2023-24.pdf",
    pageLink: "/annexure/annual-academic-calendar-2023-24",
  },
  {
    date: "24-05-2023",
    name: "Annexure-11 - List of School Management Committee",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-11-List-of-School-Management-Committee.pdf",
    pageLink: "/annexure/list-of-school-management-committee",
  },
  {
    date: "24-05-2023",
    name: "Annexure-12 - List of Parents Teachers Association",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-12-List-of-Parents-Teachers-Association.pdf",
    pageLink: "/annexure/list-of-parents-teachers-association",
  },
  {
    date: "24-05-2023",
    name: "Annexure-13 - Last Three Years Result of the Board Examination",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-13-Last-Three-Years-Result-of-the-Board-Examination.pdf",
    pageLink: "/annexure/last-three-years-result-of-the-board-examination",
  },

  {
    date: "31-08-2021",
    name: "Reopening of Schools for Students of Classes 9th to 12th",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/454_dt_31082021.pdf",
  },
  {
    date: "08-08-2021",
    name: "SOPs for Dispute Redressal 2021",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/20210808-SOPs-for-Dispute-Redressal-2021.pdf",
  },
  {
    date: "14-04-2021",
    name: "CBSE Circular dated 14.04.2021 on Class X and Class XII Board Examinations",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circular140421.pdf",
  },
  {
    date: "12-04-2021",
    name: "Directorate of Education Circular dated 12.04.2021 regarding closure of schools",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/226_dt_120420211.pdf",
  },
  {
    date: "18-02-2021",
    name: "CBSE Circular for Nursery Admission Criteria",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/Admission-Process-for-the-Open-Seats-(other-than-EWS-DG-category-Seats)-at-entry-level-classes-in-pvt-unaided-recognized-schools-in-delhi-for-the-academic-session-2021-22.PDF",
  },
  {
    date: "",
    name: "Appendix - IX - Mandatory Public Disclosure",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/APPENDIX-IX-MANDATORY-PUBLIC-DISCLOSURE.pdf",
  },
  {
    date: "25-07-2020",
    name: "Annexure - 1 (a) - Affiliation Upgradation Letter and recent extension of Affiliation",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-1(a)-Affiliation-Upgradation-Letter-and-recent-extension-of-Affiliation.pdf",
  },
  {
    date: "17-10-2018",
    name: "Annexure - 1 (b) - Affiliation Upgradation Letter and recent extension of Affiliation",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-1(b)-Affiliation-Upgradation-Letter-and-recent-extension-of-Affiliation.pdf",
  },
  {
    date: "15-03-2020",
    name: "Annexure - 3 - No Objection Certificate (NOC)",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-3-No-Objection-Certificate-(NOC).pdf",
  },
  {
    date: "15-03-2020",
    name: "Annexure - 3 (a) - No Objection Certificate (NOC)",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-3(a)-No-Objection-Certificate-(NOC).pdf",
  },
  {
    date: "15-03-2020",
    name: "Annexure - 4 - Recognition Certificate",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-4-Recognition-Certificate.pdf",
  },
  {
    date: "14-08-2008",
    name: "Annexure - 5 - Building Safety Certificate",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-5-Building-Safety-Certificate.pdf",
  },
  {
    date: "20-04-2017",
    name: "Annexure - 6 - Fire Safety Certificate",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-6-Fire-Safety-Certificate.pdf",
  },
  {
    date: "18-04-2018",
    name: "Annexure - 7 - Water Health and Sanitation Certificates",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-7-Water-Health-and-Sanitation-Certificates.pdf",
  },
  {
    date: "",
    name: "Annexure - 8 - Fee Structure of the School",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-8-Fee-Structure-of-the-School.pdf",
  },
  {
    date: "",
    name: "Annexure - 9 - Annual Academic Calendar",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-9-Annual-Academic-Calendar.pdf",
  },
  {
    date: "",
    name: "Annexure - 10 - School Management Committee",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-10-School-Management-Committee.pdf",
  },
  {
    date: "",
    name: "Annexure - 11 - List of Parents Teachers Association (PTA) Members",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/Annexure-11-List-of-Parents-Teachers-Association-(PTA)-Members.pdf",
  },
];

export default data;
