import React from "react";
import "./style.scss";
import data from "./data";
import Layout from "../../Layout";
import SEO from "../../seo";

export default function CBSECorner() {
  return (
    <Layout>
      <SEO
        title="CBSE Corner | Chinmaya Vidyalaya Vasant Vihar"
        description="View and download the latest notifications, news and information from the Central Board of Secondary Education."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="cbsecorner">
        <h1 className="heading">CBSE Corner</h1>
        <div className="cbsecorner__table">
          {/* <div className="cbsecorner__table__row cbsecorner__table__row--heading">
          <div className="cbsecorner__table__col1">S. No.</div>
          <div className="cbsecorner__table__col2">Name</div>
          <div className="cbsecorner__table__col3">Designation</div>
        </div> */}
          {data.map((item, i) => (
            <div className="cbsecorner__table__row">
              <div className="cbsecorner__table__col1">{item.date}</div>
              <div className="cbsecorner__table__col2">
                {!item.pageLink ? (
                  item.name
                ) : (
                  <a href={item.pageLink}>{item.name}</a>
                )}
              </div>
              <div className="cbsecorner__table__col3">
                <a href={item.link} download>
                  <i className="fas fa-file-download"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
